import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import { FiXSquare } from 'react-icons/fi';
import './closeButton.css';

/* 

Example Usage:

    <CloseButton /> defaults to the homepage
    <CloseButton title="Other Destination" url="/other-destination" />

*/

const CloseButton = ({ title, url }) => {
    return(    
        <Link to={url} className="closebtn">
            <FiXSquare />
            <span className="sr-only">{title}</span>
        </Link>
    )
}

CloseButton.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
  }
  
  CloseButton.defaultProps = {
    title: `Home`,
    url: `/`,
  }

export default CloseButton
