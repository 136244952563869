import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from '../components/seo'
import TopicPagination from '../components/topicPagination'
import CloseButton from "../components/closeButton"
import Grid from "../components/grid"
import GridCell from "../components/gridCell"
import TopicLink from "../components/topicLink"
import Img from 'gatsby-image'
import Bucket from "../components/bucket"
import { sortTopics } from '../components/utils/topicUtils'


function TopicGrid(topics) {
  if(topics !== null){
    const gridClasses = (topics.length <= 4 ? "topic-grid quarters" : "topic-grid");

    return(
      <Grid classNames={gridClasses}>
        {topics.map((topic, index)  => (
            <GridCell key={index} classNames="topic-grid-cell">
              <TopicLink title={topic.title} index={topic.field_topic_id} url={topic.path.alias} />
            </GridCell>
        ))}
      </Grid>
    )
  }
}

export default ({data, location}) => {
  const bucket = data.allTaxonomyTermAnnualReport.edges[0].node;
  const bucketTopics = (bucket !== null ? sortTopics(bucket.relationships.node__topic) : ``);
  const bucketText = (bucket.description !== null ? bucket.description.processed : ``);
  const bucketImage = (bucket.relationships.field_sidebar !== null ? bucket.relationships.field_sidebar : ``);
  const bucketImageAlt = (bucket.field_sidebar_alt_text !== null ? bucket.field_sidebar_alt_text : ``);
  const bucketIcon = (bucket.relationships.field_icon !== null ? bucket.relationships.field_icon : []);
  const bucketIconAlt = (bucket.field_icon_alt_text !== null ? bucket.field_icon_alt_text : ``);

  return (
    <Layout>
      <SEO title={bucket.name} keywords={[`gatsby`, `application`, `react`]} />
      <div className="bucket-page page-container">
        <div className="primary">
          <div className="page-bucket">
            <GridCell classNames={`bucket-grid-cell colourA`} tag='div'>
              <Bucket title={bucket.name} images={bucketIcon} tag='h2' alt={bucketIconAlt} />
            </GridCell>
          </div>
          <div className="page-controls">
              <CloseButton />
              <TopicPagination bucket={bucket} topics={bucketTopics} location={location} />
            </div>
          <div className="page-content">
            <div dangerouslySetInnerHTML={{ __html: bucketText }} />
          </div>
        </div>
  
        <div className="secondary">
          {TopicGrid(bucketTopics)}
        </div>
        <div className="tertiary">
          {bucketImage && bucketImage.localFile &&
          <Img alt={bucketImageAlt} fluid={bucketImage.localFile.childImageSharp.fluid} />}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
 query($slug: String!) {
  allTaxonomyTermAnnualReport(filter:{fields:{slug:{eq: $slug}}}){
    edges {
      node {
        drupal_internal__tid
        name
        description {
          processed
        }
        path {
          alias
        }
        field_sidebar_alt_text
        field_icon_alt_text
        
        relationships {
          node__topic {
            title
            field_topic_id
            path{
              alias
            }
          }
          
          field_icon{
            id
            drupal_internal__fid
            filename
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 200, maxHeight: 200) {
                ...GatsbyImageSharpFluid
                }
                fixed(width: 100, height: 100) {
                  base64
                  tracedSVG
                  aspectRatio
                  width
                  height
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  originalName
                }
              }
            }
          }

          field_sidebar {
            localFile {
              childImageSharp {
                fluid(
                  maxWidth: 800
                  quality: 80) {
                    ...GatsbyImageSharpFluid_noBase64
                }
                fixed(width: 400) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }

  }
 }
`