import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import './topicLink.css'

const TopicLink = ({ title, index, url }) => (
  <Link to={url} className="topiclink">
    <div className="topiclink-content">
      <div className="topiclink-index">{index}</div>
      <div className="topiclink-title"><span>{title}</span></div>
    </div>
  </Link>
)

TopicLink.propTypes = {
  title: PropTypes.string,
  index: PropTypes.string,
  url: PropTypes.string,
}

TopicLink.defaultProps = {
  title: ``,
  index: ``,
  url: ``,
}

export default TopicLink
