import PropTypes from 'prop-types'
import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import {Link} from 'gatsby'
import { FiPlay } from 'react-icons/fi';
import './topicPagination.css'

function stripTrailingSlashes (path){
    return(
        path === `/` ? path : path.replace(/\/$/, ``)
    )
}

function comparePaths (pathToCheck, currentPath){
    return stripTrailingSlashes(pathToCheck) === stripTrailingSlashes(currentPath)
}

function getActiveItem (items,currentPath){
    for(let i=0; i<items.length;i++){
        if(comparePaths(items[i].path.alias,currentPath)){
            return i
        }
    }
}

function getPathAlias(item){
    return(
        item === undefined ? `` : item.path.alias
    )
}

export default class TopicPagination extends React.Component {
    render() {
        // Only render if topics and bucket exist
        if(!this.props.topics || !this.props.bucket){ 
            return null; 
        }

        // Use same naming convention {title, path.alias} for both buckets and topics
        const bucketPage = { 
            title: this.props.bucket.name, 
            path: { alias: this.props.bucket.path.alias }}

        // Combine bucket and topics into a single array
        const paginationItems = [
            bucketPage,
            ...this.props.topics
        ]

        // Determine current Page URL
        const currentPath = this.props.location.pathname

        // Determine active item, previous URL and next URL
        const activeItem = getActiveItem(paginationItems,currentPath);
        let previousURL = getPathAlias(paginationItems[activeItem - 1]);
        let nextURL = getPathAlias(paginationItems[activeItem + 1]);

        if(previousURL === ``){
            previousURL = getPathAlias(paginationItems[paginationItems.length - 1]);
        }

        if(nextURL === ``){
            nextURL = getPathAlias(paginationItems[0]);
        }

        // Responsiveness - Handle truncated pagination (2 siblings + ellipsis)
        let siblingA = activeItem - 1;
        let siblingB = activeItem + 1;
        let prevEllipsis = (activeItem === 0 || activeItem === 1 ? `` : `ellipsis` );
        let nextEllipsis = ( activeItem === paginationItems.length - 1 || activeItem === paginationItems.length - 2 ? `` : `ellipsis` );

        if(activeItem === 0){
            siblingA = activeItem + 2;
        }
        if(activeItem === (paginationItems.length - 1)){
            siblingB = activeItem - 2;
        }

        return(
            <Pagination aria-label="Secondary" size="sm">
                {/* Previous Button */}
                <PaginationItem className="control">
                    <PaginationLink previous 
                        to={previousURL} 
                        tag={Link} 
                        aria-label="Previous topic"
                        className={`prev ${prevEllipsis}`}> <FiPlay /></PaginationLink>
                </PaginationItem>

                {/* Pagination Items */}
                {paginationItems.map((item, index)  => {
                    let currentPage = comparePaths(item.path.alias,this.props.location.pathname);
                    let activeSibling = (index === siblingA || index === siblingB ) ? `sibling` : ``;

                    return(
                        <PaginationItem className={`index ${activeSibling}`} key={index} active={currentPage}>
                            <PaginationLink to={item.path.alias} tag={Link} aria-current={currentPage}>
                                <span className="sr-only">Topic</span> {index}
                                <span className="sr-only">{item.title}</span>
                            </PaginationLink>
                        </PaginationItem>
                    )
                })}
                
                {/* Next Button */}
                <PaginationItem className="control">
                    <PaginationLink next 
                        to={nextURL} 
                        tag={Link} 
                        aria-label="Next topic"
                        className={`next ${nextEllipsis}`}> <FiPlay /></PaginationLink>
                </PaginationItem>
            </Pagination>
        )
    }
}

TopicPagination.propTypes = {
    bucket: PropTypes.object,
    topics: PropTypes.array,
    location: PropTypes.object,
}

/*
SAMPLE USAGE

const PaginationPage = ({data, location}) => {
    const bucket = data.allTaxonomyTermAnnualReport.edges[0].node
    const bucketTopics = bucket.relationships.node__topic
    return(
        <TopicPagination bucket={bucket} topics={bucketTopics} location={location} />
    )
}

export const query = graphql`
    query($slug: String!) {
        allTaxonomyTermAnnualReport(filter:{fields:{slug:{eq: $slug}}}){
            edges {
                node {
                    drupal_internal__tid
                    name
                    path{
                        alias
                    }
                    relationships {
                        node__topic {
                            title
                            field_topic_id
                            path{
                                alias
                            }
                        }
                    }
                }
            }

        }
    }
`

*/